import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  SubscriptionHookOptions,
  useMutation as originalUseMutation,
  useQuery as originalUseQuery,
  useLazyQuery as originUseLazyQuery,
  useSubscription as originalUseSubscription,
  NetworkStatus,
  ApolloError,
  useApolloClient,
  FetchPolicy,
} from '@apollo/client'
import Router from 'next/router'
import {
  DoubleworkListProps,
  EditCCPositionFormValues,
  EditJTPositionFormValues,
  PositionDetailProps,
  PositionListProps,
  SearchDoubleworkFormProps,
  SearchPositionFormProps,
  SearchResumeFormProps,
  ResumeListProps,
  EditCareerScoutFormValues,
} from '@persol-epdndo/design-systems'
import _, { isNaN } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  PledgeVersion,
  PositionStatus,
  PositionType,
  TokenPayload,
  WorkType,
  AppErrors,
  AppCode,
  EntryType,
  WorkTypeName,
  CursorBasePaginationSortType,
  jstDayjs,
  CareerScoutStatus,
  SurveyType,
  SurveyTypeName,
  AnnualScheduleType,
  AuthRole,
  UserPermissionAttributes,
} from '@persol-epdndo/base-shared'
import {
  CreateCareerScoutInput,
  CreatePositionInput,
  CareerScoutItem,
  ManagePositionItem,
  QueryPositionResponseItem,
  QueryPositionsResponse,
  UpdateCareerScoutInput,
  UpdatePositionInput,
} from './queries'
import { ParsedUrlQueryInput } from 'querystring'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ja'
import * as Sentry from '@sentry/nextjs'
import { PageProps } from './pages/_app'
import { SaveResumeSearchCondition } from './pages/manage/resumes'

dayjs.extend(utc)
dayjs.extend(timezone)

const useQueryCacheExpireMap = new Map<string, number>()

export const isBrowser = typeof window !== 'undefined'
export interface UserAgreement {
  accountCustomTerms?: string
}

export interface UserInfo {
  attributes: TokenPayload
  username: string
}

export type JSONPrimitive = string | number | boolean | null

export type JSONValue = JSONPrimitive | JSONObject | JSONArray

export interface JSONObject {
  [member: string]: JSONValue
}

export interface JSONArray extends Array<JSONValue> {}

function controlCacheUseQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables> & { ttl?: number },
) {
  const [loadedNetwork, setLoadedNetwork] = useState<boolean>(false)
  const cacheKey = (query.loc?.source.body ?? '' + JSON.stringify(options?.variables)).replace(/\s/g, '')
  let validTTLOption = false
  if (options?.ttl !== undefined) {
    if (options.fetchPolicy !== undefined && options.fetchPolicy !== 'cache-first') {
      console.warn('The ttl option is valid only when the fetchPolicy is cache-first.')
    } else {
      validTTLOption = true
      if ((useQueryCacheExpireMap.get(cacheKey) ?? 0) <= new Date().getTime()) {
        options.fetchPolicy = 'cache-and-network'
      }
    }
  }

  const result = originalUseQuery(query, _.omit(options, 'ttl'))

  useEffect(() => {
    if (validTTLOption) {
      if (result.networkStatus === NetworkStatus.loading) {
        setLoadedNetwork(true)
      }
      if (loadedNetwork && result.error === undefined && result.data !== undefined) {
        useQueryCacheExpireMap.set(cacheKey, new Date().getTime() + (options?.ttl ?? 0))
      }
    }
  }, [result.networkStatus, result.error, result.data])
  return result
}

export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables> & { ttl?: number },
) {
  const { enqueueSnackbar } = useSnackbar()
  const result = controlCacheUseQuery(query, options)

  useEffect(() => {
    if (result.error !== undefined) {
      enqueueSnackbar(getErrorMessage({ error: result.error }))
    }
  }, [result.error])
  return result
}

export function useLazyQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
) {
  const { enqueueSnackbar } = useSnackbar()
  const result = originUseLazyQuery(query, options)

  useEffect(() => {
    if (result[1].error !== undefined) {
      enqueueSnackbar(getErrorMessage({ error: result[1].error }))
    }
  }, [result[1].error])
  return result
}

export function useAsyncLazyQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  fetchPolicy: FetchPolicy = 'cache-first',
) {
  const client = useApolloClient()
  return useCallback(
    async (variables: TVariables) =>
      await client.query<TData, TVariables>({
        query,
        variables,
        fetchPolicy,
      }),
    [client],
  )
}

export function useMutation<TData = any, TVariables extends OperationVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
) {
  const { enqueueSnackbar } = useSnackbar()
  const result = originalUseMutation(mutation, options)

  useEffect(() => {
    if (result[1].error !== undefined) {
      enqueueSnackbar(getErrorMessage({ error: result[1].error }))
    }
  }, [result[1].error])
  return result
}

export function useSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode,
  options?: SubscriptionHookOptions<TData, TVariables>,
) {
  const { enqueueSnackbar } = useSnackbar()
  const result = originalUseSubscription(subscription, options)

  useEffect(() => {
    if (result.error !== undefined) {
      enqueueSnackbar(getErrorMessage({ error: result.error }))
    }
  }, [result.error])
  return result
}

// eslint-disable-next-line prettier/prettier
export function getErrorMessage({ error, code }: { error?: ApolloError, code?: AppCode }) {
  let errorMessage = '予期せぬエラーが発生しました'
  // API側で定義したエラー
  const appCode = code ?? error?.graphQLErrors.find(x => x.extensions?.appCode)?.extensions?.appCode
  const appError = AppErrors.find(x => x.appCode === appCode)
  const statusCode = error?.graphQLErrors.find(x => x.extensions?.code)?.extensions?.code

  if (appError !== undefined) {
    errorMessage = appError.clientMessage
  } else if (statusCode === 'FORBIDDEN_ERROR') {
    errorMessage = '権限がありません'
  }
  return errorMessage
}

export type PositionFormValues =
  | (EditCCPositionFormValues & Partial<EditJTPositionFormValues>)
  | (EditJTPositionFormValues & Partial<EditCCPositionFormValues>)

export function positionFormValuesToCreatePositionInput(
  values: PositionFormValues,
  status: PositionStatus,
  type: PositionType,
): CreatePositionInput {
  return {
    // CCのみ
    arrivalFrequency: values.arrivalFrequency,
    transfer: values.transfer,
    personnelComment: values.personnelComment,
    requiredExperience: values.requiredExperience,
    annualIncome: values.annualIncome,
    socialInsurance: values.socialInsurance,
    // JTのみ
    capacity: values.capacity,
    workType: values.workType !== undefined ? (Number(values.workType) as WorkType) : undefined,
    pointsToLearn: values.pointsToLearn,
    trainerUserId: values.trainerUser === null || values.trainerUser === undefined ? null : values.trainerUser.value,
    isAllowRemote: values.isAllowRemote === '1',
    periodStartedAt: values.workStartedAt,
    periodEndedAt: values.workEndedAt,
    pledgeVersion:
      values.isPledgeFormChecked === true && type === PositionType.JobTrial && status === PositionStatus.BeforeApproval
        ? PledgeVersion.Position
        : undefined,
    requiredEnviroment: values.requiredEnviroment,
    nearestStation: values.nearestStation,
    // 共通
    positionType: type,
    isOpenToGroup: values.isOpenToAccount,
    isOpenToOrganization: values.isOpenToOrganization,
    organizationId: values.organizationId,
    name: values.name,
    openStartedAt: values.openStartedAt,
    openEndedAt: values.openEndedAt,
    applicationStartedAt: values.applicationStartedAt,
    applicationEndedAt: values.applicationEndedAt,
    responsibleUserIds: values.responsibleUserIds.map(x => x.value),
    contactUserIds: values.contactUserIds,
    contactEmails: values.useContactEmails ? values.contactEmailsString?.split(',') : [],
    employmentTypeIds: values.employmentTypeIds,
    jobClassificationId: values.jobClassificationId === '' ? null : values.jobClassificationId,
    department: values.department,
    mission: values.mission,
    officeIds: values.officeIds,
    workingLocationIds: values.workingLocationIds,
    workingHour: values.workingHour,
    authorComment: values.authorComment,
    tagNames: values.tags.map(x => x.label),
    image: values.images[0]?.key ?? '',
    detailImages: values.detailImages.map(x => x.key ?? ''),
    detailCaptions: values.detailImages.map(x => x.caption ?? ''),
    status,
    applicationRequirement: values.applicationRequirement,
  }
}

export function positionFormValuesToUpdatePositionInput(
  values: PositionFormValues,
  status: PositionStatus,
  type: PositionType,
  id: string,
): UpdatePositionInput {
  const createInput = positionFormValuesToCreatePositionInput(values, status, type)
  return { id, ...createInput }
}

export function managePositionToPositionFormValues(
  item: ManagePositionItem,
): EditCCPositionFormValues & EditJTPositionFormValues {
  return {
    // CCのみ
    arrivalFrequency: item.arrivalFrequency,
    transfer: item.transfer,
    personnelComment: item.personnelComment,
    requiredExperience: item.requiredExperience,
    annualIncome: item.annualIncome,
    socialInsurance: item.socialInsurance,
    // JTのみ
    capacity: item.capacity,
    workType: item.workType === 0 ? '' : String(item.workType),
    pointsToLearn: item.pointsToLearn,
    trainerUser:
      item.trainerUser === null
        ? null
        : {
            label: `${item.trainerUser.lastName}${item.trainerUser.firstName}`,
            value: item.trainerUser.id,
          },
    isAllowRemote: item.isAllowRemote === null ? '' : item.isAllowRemote ? '1' : '0',
    workStartedAt: item.periodStartedAt ?? new Date(),
    workEndedAt: item.periodEndedAt ?? new Date(),
    isPledgeFormChecked: false,
    requiredEnviroment: item.requiredEnviroment,
    nearestStation: item.nearestStation,
    // 共通
    isOpenToAccount: item.isOpenToGroup,
    isOpenToOrganization: item.isOpenToOrganization,
    organizationId: item.organization.id,
    name: item.name,
    openStartedAt: item.openStartedAt,
    openEndedAt: item.openEndedAt,
    applicationStartedAt: item.applicationStartedAt,
    applicationEndedAt: item.applicationEndedAt,
    responsibleUserIds: item.responsibleUsers.map(x => ({
      label: `${x.lastName}${x.firstName}`,
      value: x.id,
    })),
    contactUserIds: item.contactUserIds,
    useContactEmails: item.contactEmails.length !== 0,
    contactEmailsString: item.contactEmails.join(','),
    employmentTypeIds: item.employmentTypeIds,
    jobClassificationId: item.jobClassification?.id ?? '',
    department: item.department,
    mission: item.mission,
    officeIds: item.officeIds,
    workingLocationIds: item.workingLocationIds,
    workingHour: item.workingHour,
    authorComment: item.authorComment,
    tags: item.positionTag.map(x => ({ label: x.tag.name, value: x.tag.id })),
    images: item.image === '' ? [] : [{ key: item.image, url: item.imageUrl }],
    detailImages: item.detailImages.map((x, i) => ({
      key: x,
      url: item.detailImageUrls[i],
      caption: item.detailCaptions[i],
    })),
    applicationRequirement: item.applicationRequirement,
  }
}

function formatDateString(dateString: string | null) {
  if (dateString === null) {
    return ''
  }
  return jstDayjs(dateString).format('YYYY/MM/DD')
}

export function positionsResponseItemToPositionListPropsPosition(
  item: QueryPositionsResponse['positions']['items'][0],
): PositionListProps['positions'][0] {
  return {
    id: item.id,
    name: item.name,
    image: item.imageUrl === '' ? '/position_default_cover.jpg' : item.imageUrl,
    organizationName: item.organization.name,
    departmentName: item.department,
    workingLocationName: item.workingLocations.map(x => x.name).join(', '),
    openPeriod: `${formatDateString(item.openStartedAt)} - ${formatDateString(item.openEndedAt)}`,
    entryPeriod: `${formatDateString(item.applicationStartedAt)} - ${formatDateString(item.applicationEndedAt)}`,
    tags: [
      {
        id: 'positionType',
        label: item.positionType === PositionType.CareerCharenge ? 'キャリチャレ' : 'ジョブトラ',
        // NOTE: 色をどこかで持つ
        color: item.positionType === PositionType.CareerCharenge ? '#FA8904' : '#0466FA',
      },
      {
        id: 'jobClassification',
        label: item.jobClassification.name,
        // NOTE: 色をどこかで持つ
        color: '#999999',
      },
    ].concat(
      item.positionTag.map(x => ({
        id: x.tag.id,
        label: x.tag.name,
        // NOTE: 色をどこかで持つ
        color: '#42B3AF',
      })),
    ),
    isBookmarked: item.isBookmarked,
  }
}

export type PositionSearchCondition = SearchPositionFormProps['defulatValues'] &
  PositionListProps['defaultValue'] &
  Partial<{
    seed: string
    after: string
    limit: number
  }>

export interface PositionSearchConditionUrlQuery extends ParsedUrlQueryInput {
  pt: string
  et: string | string[]
  jc: string
  wl: string
  o: string
  ar: string | string[]
  wt: string | string[]
  ic: string
  t: string | string[]
  k: string | string[]
  ob: string
  st: string
  sd: string
}

export function positionsSearchConditionToUrlQuery(
  condition: PositionSearchCondition,
): PositionSearchConditionUrlQuery {
  const query = {
    pt: String(condition.positionType),
    et: condition.employmentTypeIds,
    jc: condition.jobClassificationId === undefined ? '' : condition.jobClassificationId,
    wl: condition.workingLocationId === undefined ? '' : condition.workingLocationId,
    o: condition.organizationId === undefined ? '' : condition.organizationId,
    ar: condition.isAllowRemotes.map(x => String(x)),
    wt: condition.workTypes.map(x => String(x)),
    ic: String(condition.isIncludeClosed),
    t: condition.tagIds,
    k: condition.keywords,
    ob: String(condition.isOnlyBookmark),
    st: String(condition.sortType),
    sd: condition.seed === undefined ? '' : condition.seed,
  }
  return query
}

export function urlQueryToPositionsSearchCondition(
  query: Partial<PositionSearchConditionUrlQuery>,
  defaultCondition: PositionSearchCondition,
): PositionSearchCondition {
  const conditionFromUrl = _.pickBy(
    {
      positionType: query.pt === undefined ? undefined : Number(query.pt),
      employmentTypeIds: _.isString(query.et) ? [query.et] : query.et,
      jobClassificationId: query.jc,
      workingLocationId: query.wl,
      organizationId: query.o,
      isAllowRemotes: _.isString(query.ar) ? [query.ar === 'true'] : query.ar?.map(x => x === 'true'),
      workTypes: _.isString(query.wt) ? [Number(query.wt)] : query.wt?.map(x => Number(x)),
      isIncludeClosed: query.ic === 'true',
      tagIds: _.isString(query.t) ? [query.t] : query.t,
      keywords: _.isString(query.k) ? [query.k] : query.k,
      isOnlyBookmark: query.ob === 'true',
      sortType: query.st === undefined || query.st === '' ? undefined : Number(query.st),
      seed: query.sd === undefined || query.sd === '' ? '' : query.sd,
    },
    x => x !== undefined && x !== '',
  )
  return { ...defaultCondition, ...conditionFromUrl }
}

export function convertPositionItemToPositionDetailProps(position: QueryPositionResponseItem): PositionDetailProps {
  return {
    positionId: position.id,
    positionName: position.name,
    positionType: position.positionType,
    organization: position.organization.name,
    department: position.department,
    tags: [
      {
        text: position.positionType === PositionType.CareerCharenge ? 'キャリチャレ' : 'ジョブトラ',
        color: position.positionType === PositionType.CareerCharenge ? 'orange' : 'blue',
      },
      {
        text: position.jobClassification.name,
        color: 'grey',
      },
    ].concat(
      position.positionTag.map(item => ({
        text: item.tag.name,
        color: 'green',
      })),
    ) as PositionDetailProps['tags'],
    imageUrl: position.imageUrl === '' ? '/position_default_cover.jpg' : position.imageUrl,
    isBookmarked: position.isBookmarked,
    positionDetails: {
      title: position.positionType === PositionType.CareerCharenge ? '業務内容' : '体験内容',
      text: position.positionType === PositionType.CareerCharenge ? position.mission : '',
      items:
        position.positionType === PositionType.CareerCharenge
          ? []
          : [
              {
                title: '体験パターン',
                text: WorkTypeName[position.workType] ?? '(体験パターン)',
              },
              {
                title: '体験内容',
                urlToLink: true,
                text: position.mission,
              },
              {
                title: '体験を通して学べること',
                urlToLink: true,
                text: position.pointsToLearn,
              },
            ],
    },
    applicationRequirements: [
      {
        title: '応募要件',
        text: position.applicationRequirement,
      },
      {
        title: '雇用区分',
        text: position.employmentTypes.map(x => x.name).join('、'),
      },
      position.positionType === PositionType.CareerCharenge
        ? {
            title: '必要業務経験',
            urlToLink: true,
            text: position.requiredExperience,
          }
        : {
            title: '必要環境',
            text: position.requiredEnviroment,
          },
    ],
    detailImageUrlAndCaptions: position.detailImageUrls.map((x, index) => {
      return {
        src: x,
        caption: position.detailCaptions[index],
      }
    }),
    applicationConditions:
      position.positionType === PositionType.CareerCharenge
        ? {
            firstPart: [
              {
                title: '就業時間',
                text: position.workingHour,
              },
              {
                title: '予定年収',
                text: position.annualIncome,
              },
            ],
            secondPart: [
              {
                title: '出社頻度',
                // NOTE: 出社頻度は必須項目だが運用中に追加されたので既存のCCの場合空文字が入っている
                // その場合は案件登録時のプレビューとは違って(出社頻度)ではなく--と表示する
                text: _.isEmpty(position.arrivalFrequency) ? '--' : position.arrivalFrequency,
              },
              {
                title: '転勤',
                text: position.transfer,
              },
              {
                title: '社会保険',
                text: position.socialInsurance,
              },
            ],
          }
        : {
            firstPart: [
              {
                title: '定員数',
                text: `${position.capacity}名`,
              },
              {
                title: '実施方法',
                text: position.isAllowRemote ? 'リモート' : '対面',
              },
              {
                title: '期間',
                text: `${jstDayjs(position.periodStartedAt).format('YYYY年M月D日')} - ${jstDayjs(
                  position.periodEndedAt,
                ).format('YYYY年M月D日')}`,
              },
              {
                title: '時間',
                text: position.workingHour,
              },
            ],
            secondPart: [
              {
                title: '最寄り駅',
                text: position.nearestStation,
              },
            ],
          },
    offices: position.offices.map(office => {
      return { ...office, apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_EMBED_API_KEY as string }
    }),
    contactUsers: position.contactUsers,
    contactEmails: position.contactEmails,
    authorComment: position.authorComment === '' ? 'ご興味のある方は、是非ご応募ください' : position.authorComment,
  }
}

export type DoubleworkSearchCondition = SearchDoubleworkFormProps['defulatValues'] &
  DoubleworkListProps['defaultValue'] &
  Partial<{
    seed: string
    self: string | null
    before: string | null
    after: string | null
    limit: number
  }>

export interface DoubleworkSearchConditionUrlQuery extends ParsedUrlQueryInput {
  wl: string
  wm: string
  ws: string
  o: string
  ic: string
  t: string | string[]
  k: string | string[]
  ob: string
  st: string
  sd: string
}

export function doubleworksSearchConditionToUrlQuery(
  condition: DoubleworkSearchCondition,
): DoubleworkSearchConditionUrlQuery {
  const query = {
    wl: condition.workLargeCategoryId === undefined ? '' : condition.workLargeCategoryId,
    wm: condition.workMediumCategoryId === undefined ? '' : condition.workMediumCategoryId,
    ws: condition.workSubCategoryId === undefined ? '' : condition.workSubCategoryId,
    o: condition.organizationId === undefined ? '' : condition.organizationId,
    ic: String(condition.isIncludeClosed),
    t: condition.tagIds,
    k: condition.keywords,
    ob: String(condition.isOnlyBookmark),
    st: String(condition.sortType),
    sd: condition.seed === undefined ? '' : condition.seed,
  }
  return query
}

export function urlQueryToDoubleworksSearchCondition(
  query: Partial<DoubleworkSearchConditionUrlQuery>,
  defaultCondition: DoubleworkSearchCondition,
): DoubleworkSearchCondition {
  const conditionFromUrl = _.pickBy(
    {
      workLargeCategoryId: query.wl,
      workMediumCategoryId: query.wm,
      workSubCategoryId: query.ws,
      organizationId: query.o,
      isIncludeClosed: query.ic === 'true',
      tagIds: _.isString(query.t) ? [query.t] : query.t,
      keywords: _.isString(query.k) ? [query.k] : query.k,
      isOnlyBookmark: query.ob === 'true',
      sortType:
        _.isString(query.st) && Object.values(CursorBasePaginationSortType).some(x => x === Number(query.st))
          ? Number(query.st)
          : undefined,
      seed: query.sd === undefined || query.sd === '' ? '' : query.sd,
    },
    x => x !== undefined && x !== '',
  )
  return { ...defaultCondition, ...conditionFromUrl }
}

// MEMO: 所属組織の並べ替え（主務を最初にする）
interface Organization {
  id: string
  name: string
}
export function sortOrganizations(mainId: string, organizations: Organization[]) {
  // MEMO: organizationsはread onlyなので直接sort()できない
  const result = organizations.slice().sort(function (a, b) {
    if (a.id === mainId) {
      return -1
    } else if (a.id > b.id) {
      return 1
    } else {
      return -1
    }
  })
  return result
}

interface EmploymentType {
  id: string
  name: string
}

export function filterValidEmploymentTypes(employmentTypes: EmploymentType[]) {
  return employmentTypes.filter(x => x.name.match(/[A-Z]職/))
}

// NOTE:
// - 300090: PERSOL INNOVATION FUND合同会社
// - 300077: パーソルファシリティマネジメント株式会社
// - 800007: 社会保険労務士法人 TSR
// この3社についてはすべてPHDからの出向社員としてPHDが作成するポジションを募集するため、
// 上記3社に対してのポジションが存在しないという誤解を与えないようにポジション検索画面などで
// 検索条件の選択肢から除外するためのメソッド。
export function filterOnlySecondedPositionOrganizations(
  organizations: Array<{
    label: string
    value: string
    externalId: string
  }>,
) {
  return organizations.filter(x => !['300090', '300077', '800007'].includes(x.externalId ?? ''))
}

export function usePrevious(value: any): any {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDeepEffect(callBack: React.EffectCallback, dependency: React.DependencyList) {
  const prevDependency = usePrevious(dependency)
  useEffect(() => {
    if (!_.isEqual(prevDependency, dependency)) {
      callBack()
    }
  }, dependency)
}

export function urlQueryParamToNumber(param: string | string[] | undefined, defaultValue: number = 0): number {
  if (typeof param === 'string') {
    return parseInt(param) ?? defaultValue
  }
  return defaultValue
}

export function moveOrBack(nextPath: string, prevePath: string = '') {
  if (prevePath.startsWith(nextPath)) {
    Router.push(prevePath)
  } else {
    Router.push(nextPath)
  }
}

// NOTE:
// パーソルキャリアの個社内キャリチャレのみすべて選考結果タブ非表示にする
export function checkDisableEntryResult(entryType: EntryType, homeOrganizationName: string) {
  return entryType === EntryType.IndividualCareerCharenge && ['パーソルキャリア'].includes(homeOrganizationName)
}

export function moveIfDifferentPath(path: string) {
  if (Router.pathname !== path) {
    Router.push(path)
  }
}

export interface LocalStorage {
  manageEntriesSearchCondition: {
    limit: number
  }
  manageCareerScoutEntriesSearchCondition: {
    limit: number
  }
  mypageAnnualScheduleCondition: {
    annualScheduleTypes: AnnualScheduleType[]
  }
  resumeSearchCondition: {
    searchCondition: SaveResumeSearchCondition[]
  }
}

export function useLocalStorage<T extends LocalStorage[K], K extends keyof LocalStorage>(key: K, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (!isBrowser) {
        return initialValue
      }
      const item = window.localStorage.getItem(key)
      if (item === null) {
        return initialValue
      }
      const parseItem = JSON.parse(item)
      // 保存されているTypeが違う場合は初期値を返す
      if (typeof parseItem !== typeof initialValue) {
        return initialValue
      }
      // object形式の場合は初期値とマージして返す
      if (typeof parseItem === 'object') {
        return { ...initialValue, ...parseItem }
      }
      return parseItem
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      if (!isBrowser) {
        return
      }
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }
  return [storedValue, setValue] as const
}

export const globalState = {
  positionFocusId: {
    value: '',
    usePaths: ['/positions', '/positions/[id]'],
  },
  doubleworkFocusId: {
    value: '',
    usePaths: ['/doubleworks', '/doubleworks/[id]'],
  },
  resumeFocusId: {
    value: '',
    usePaths: ['/manage/resumes', '/manage/resumes/[id]'],
  },
  careerScoutOfferId: {
    value: '',
    usePaths: ['/manage/offers', '/manage/offers/[id]'],
  },
  careerScoutEntryId: {
    value: '',
    usePaths: ['/manage/careerScoutEntries', '/careerScoutEntries/[id]'],
  },
  careerScoutId: {
    value: '',
    usePaths: ['/manage/careerScouts', '/manage/careerScouts/[id]'],
  },
  interviewId: {
    value: '',
    usePaths: ['/manage/careerScoutInterviews', '/careerScoutEntries/[id]/interviews/[interviewId]'],
  },
}

export function getIsPrivateNavigation(navigations: PageProps['navigations']) {
  if (Array.isArray(navigations)) {
    return navigations.some(x => _.get(x.type, 'displayName') === 'PrivatePageNavigation')
  } else {
    return _.get(navigations.type, 'displayName') === 'PrivatePageNavigation'
  }
}

export function getWorkshopTitle(isManager: boolean) {
  return isManager ? 'Smyleキャリアデザイントレーニング' : 'Smyleキャリアデザインワークショップ'
}

export function getWorkshopSurveyTitle(
  surveyType: SurveyType,
  workshopType: {
    isManager: boolean
    name: string
  },
) {
  // eslint-disable-next-line no-irregular-whitespace
  return `Smyle${workshopType.isManager ? 'キャリアデザイントレーニング' : 'キャリアデザインワークショップ'}　${
    SurveyTypeName[surveyType] ?? ''
  }アンケート${workshopType.isManager ? '' : `(${workshopType.name})`}`
}

export function isValidId(data: any) {
  const regex = new RegExp('[1-9][0-9]*')
  return typeof data === 'string' && regex.test(data)
}

export function isGlobalChallengePeriod() {
  return true
}

export type ResumeSearchCondition = SearchResumeFormProps['defaultValues'] &
  ResumeListProps['defaultValue'] &
  Partial<{
    sortType: number
    seed: string
    self: string | null
    before: string | null
    after: string | null
    limit: number
  }> & {
    isExpandItem?: boolean
  }

export interface ResumeSearchConditionUrlQuery extends ParsedUrlQueryInput {
  ao: string
  og: string
  oi: string
  p: string
  jc: string
  wl: string
  s: string | string[]
  // 配列をJSON.stringifyして保存
  qg: string
  if: string
  it: string
  eo: string
  k: string | string[]
  ob: string
  st: string
  sd: string
  ie: string
}

export function resumeSearchConditionToUrlQuery(condition: ResumeSearchCondition): ResumeSearchConditionUrlQuery {
  // isOpenToGroupScout, isOpenToIndividualScout両方falseの場合は、両方trueとみなす
  // コンポーネントでも両方falseの場合は全体選択とみなしている
  const isOpenBothFalsy = condition.isOpenToGroupScout === false && condition.isOpenToIndividualScout === false
  const query = {
    ao: condition.authOrganizationId ?? '',
    og: isOpenBothFalsy ? 'true' : String(condition.isOpenToGroupScout ?? true),
    oi: isOpenBothFalsy ? 'true' : String(condition.isOpenToIndividualScout ?? true),
    p: condition.positionId ?? '',
    ho: condition.homeOrganizationId ?? '',
    cj: condition.currentJobClassificationId ?? '',
    jc: condition.jobClassificationId ?? '',
    wl: condition.workingLocationId ?? '',
    s: condition.skillIds ?? [],
    qg: encodeURIComponent(JSON.stringify((condition.qualificationGrades ?? []).map(x => `${x.id},${x.score ?? ''}`))),
    if: String(condition.incomeFrom ?? ''),
    it: String(condition.incomeTo ?? ''),
    eo: String(condition.excludeOffered ?? true),
    k: condition.keywords ?? [],
    ob: String(condition.isOnlyBookmarked ?? true),
    st: String(condition.sortType ?? ''),
    sd: condition.seed ?? '',
    ie: String(condition.isExpandItem ?? true),
  }
  return query
}

export function urlQueryToResumeSearchCondition(query: Partial<ResumeSearchConditionUrlQuery>): ResumeSearchCondition {
  const defaultValues = {
    authOrganizationId: undefined,
    isOpenToGroupScout: true,
    isOpenToIndividualScout: true,
    positionId: undefined,
    homeOrganizationId: undefined,
    currentJobClassificationId: undefined,
    jobClassificationId: undefined,
    workingLocationId: undefined,
    skillIds: [],
    qualificationGrades: [],
    incomeFrom: undefined,
    incomeTo: undefined,
    excludeOffered: false,
    keywords: [],
    isOnlyBookmarked: false,
    sortType: CursorBasePaginationSortType.Random,
    seed: new Date().getTime().toString(),
    limit: 20,
    isExpandItem: undefined,
  }

  let qualificationGrades: ResumeSearchCondition['qualificationGrades'] = []
  try {
    const queryGrades = JSON.parse(decodeURIComponent(query.qg ?? ''))
    if (_.isArray(queryGrades)) {
      qualificationGrades = queryGrades
        .filter(x => {
          // ,区切りで２つに分けられるか厳しくチェックする
          return _.isString(x) && x.split(',').length === 2
        })
        .map(x => {
          const value = x.split(',')
          const id = value[0] ?? ''
          const score = _.isEmpty(value[1]) || isNaN(Number(value[1])) ? null : Number(value[1])
          return {
            id,
            score,
          }
        })
    }
  } catch {}
  const conditionFromUrl = _.pickBy(
    {
      authOrganizationId: query.ao,
      isOpenToGroupScout: query.og !== 'false',
      isOpenToIndividualScout: query.oi !== 'false',
      positionId: query.p,
      homeOrganizationId: query.ho,
      currentJobClassificationId: query.cj,
      jobClassificationId: query.jc,
      workingLocationId: query.wl,
      skillIds: _.isString(query.s) ? [query.s] : query.s,
      qualificationGrades,
      incomeFrom: parseInt(query.if ?? ''),
      incomeTo: parseInt(query.it ?? ''),
      excludeOffered: query.eo === 'true',
      keywords: _.isString(query.k) ? [query.k] : query.k,
      isOnlyBookmarked: query.ob === 'true',
      sortType: parseInt(query.st ?? ''),
      seed: query.sd,
      isExpandItem: query.ie === 'true',
    },
    x => x !== undefined && x !== '' && !isNaN(x),
  )
  return {
    ...defaultValues,
    ...conditionFromUrl,
  }
}

export function parseQueryString(queryString: string): Record<string, string | string[]> {
  // queryString内に同じパラメータが複数ある場合は、valueを配列にして返す（例 s: ['464', '480'])
  const params = new URLSearchParams(queryString)
  const result: Record<string, string | string[]> = {}

  params.forEach((value, key) => {
    if (result[key] !== undefined) {
      // すでにキーが存在する場合
      if (Array.isArray(result[key])) {
        // すでに配列なら追加
        ;(result[key] as string[]).push(value)
      } else {
        // まだ配列でないなら、配列に変換して追加
        result[key] = [result[key] as string, value]
      }
    } else {
      // キーが存在しない場合は新規作成
      result[key] = value
    }
  })

  return result
}

export function careerScoutFormValuesToCreateCareerScoutInput(
  values: EditCareerScoutFormValues,
  status: CareerScoutStatus,
): CreateCareerScoutInput {
  return {
    isOpenToGroup: values.isOpenToGroup,
    isOpenToOrganization: values.isOpenToOrganization,
    applicationStartedAt: values.applicationStartedAt,
    applicationEndedAt: values.applicationEndedAt,
    organizationId: values.organizationId,
    name: values.name,
    mission: values.mission,
    jobClassificationId: values.jobClassificationId === '' ? null : values.jobClassificationId,
    department: values.department,
    tagNames: values.tags.map(x => x.label),
    applicationRequirement: values.applicationRequirement,
    employmentTypeIds: values.employmentTypeIds,
    requiredExperience: values.requiredExperience,
    workingHour: values.workingHour,
    annualIncome: values.annualIncome,
    workingLocationIds: values.workingLocationIds,
    officeIds: values.officeIds,
    arrivalFrequency: values.arrivalFrequency,
    transfer: values.transfer,
    socialInsurance: values.socialInsurance,
    selectionFlow: values.selectionFlow,
    responsibleUserIds: values.responsibleUserIds.map(x => x.value),
    contactUserIds: values.contactUserIds,
    contactEmails: values.useContactEmails ? values.contactEmailsString?.split(',') : [],
    authorComment: values.authorComment,
    personnelComment: values.personnelComment,
    image: values.images[0]?.key ?? '',
    detailImages: values.detailImages.map(x => x.key ?? ''),
    detailCaptions: values.detailImages.map(x => x.caption ?? ''),
    status,
  }
}

export function careerScoutFormValuesToUpdateCareerScoutInput(
  values: EditCareerScoutFormValues,
  status: CareerScoutStatus,
  id: string,
): UpdateCareerScoutInput {
  const createInput = careerScoutFormValuesToCreateCareerScoutInput(values, status)
  return { id, ...createInput }
}

export function manageCareerScoutToCareerScoutFormValues(item: CareerScoutItem): EditCareerScoutFormValues {
  return {
    isOpenToGroup: item.isOpenToGroup,
    isOpenToOrganization: item.isOpenToOrganization,
    applicationStartedAt: item.applicationStartedAt,
    applicationEndedAt: item.applicationEndedAt,
    organizationId: item.organization.id,
    name: item.name,
    mission: item.mission,
    jobClassificationId: item.jobClassification?.id ?? '',
    department: item.department,
    tags: item.careerScoutTag.map(x => ({ label: x.tag.name, value: x.tag.id })),
    applicationRequirement: item.applicationRequirement,
    employmentTypeIds: item.employmentTypeIds,
    requiredExperience: item.requiredExperience,
    workingHour: item.workingHour,
    annualIncome: item.annualIncome,
    workingLocationIds: item.workingLocationIds,
    officeIds: item.officeIds,
    arrivalFrequency: item.arrivalFrequency,
    transfer: item.transfer,
    socialInsurance: item.socialInsurance,
    selectionFlow: item.selectionFlow,
    responsibleUserIds: item.responsibleUsers.map(x => ({
      label: `${x.lastName}${x.firstName}`,
      value: x.id,
    })),
    contactUserIds: item.contactUserIds,
    useContactEmails: item.contactEmails.length !== 0,
    contactEmailsString: item.contactEmails.join(','),
    authorComment: item.authorComment,
    personnelComment: item.personnelComment,
    images: item.image === '' ? [] : [{ key: item.image, url: item.imageUrl }],
    detailImages: item.detailImages.map((x, i) => ({
      key: x,
      url: item.detailImageUrls[i],
      caption: item.detailCaptions[i],
    })),
  }
}

export function managePositionToCareerScoutFormValues(
  item: ManagePositionItem,
  availableResponsibleUsers: string[],
): EditCareerScoutFormValues {
  return {
    isOpenToGroup: false, // 公開範囲はどちらもfalseにして選び直すようにしている
    isOpenToOrganization: false,
    applicationStartedAt: item.applicationStartedAt,
    applicationEndedAt: item.applicationEndedAt,
    organizationId: item.organization.id,
    name: item.name,
    mission: item.mission,
    jobClassificationId: item.jobClassification?.id ?? '',
    department: item.department,
    tags: item.positionTag.map(x => ({ label: x.tag.name, value: x.tag.id })),
    applicationRequirement: item.applicationRequirement,
    employmentTypeIds: item.employmentTypeIds,
    requiredExperience: item.requiredExperience,
    workingHour: item.workingHour,
    annualIncome: item.annualIncome,
    workingLocationIds: item.workingLocationIds,
    officeIds: item.officeIds,
    arrivalFrequency: item.arrivalFrequency,
    transfer: item.transfer,
    socialInsurance: item.socialInsurance,
    selectionFlow: '',
    responsibleUserIds: item.responsibleUsers
      .filter(x => availableResponsibleUsers.includes(x.id))
      .map(x => ({
        label: `${x.lastName}${x.firstName}`,
        value: x.id,
      })),
    contactUserIds: item.contactUserIds,
    useContactEmails: item.contactEmails.length !== 0,
    contactEmailsString: item.contactEmails.join(','),
    authorComment: item.authorComment,
    personnelComment: item.personnelComment,
    images: item.image === '' ? [] : [{ key: item.image, url: item.imageUrl }],
    detailImages: item.detailImages.map((x, i) => ({
      key: x,
      url: item.detailImageUrls[i],
      caption: item.detailCaptions[i],
    })),
  }
}

interface MenuItem {
  label: string
  url: string
}

interface Menu {
  testId: string
  label: string
  items: MenuItem[]
}

export const getSystemModeMenus = (roles: AuthRole[]): Menu[] => {
  const menus: Menu[] = []
  if (roles.includes(AuthRole.SuperAdmin)) {
    menus.push({
      testId: 'system-organization-menu',
      label: '組織を管理する',
      items: [
        {
          label: 'ユーザー管理',
          url: '/system/chrUsers',
        },
        {
          label: 'ユーザー(非CHR)管理',
          url: '/system/nonChrUsers',
        },
        {
          label: '個社管理者管理',
          url: '/system/organizationAdminUsers',
        },
        {
          label: 'グループ管理者管理',
          url: '/system/accountAdminUsers',
        },
        {
          label: '会社管理',
          url: '/system/organizations',
        },
        {
          label: '部署管理',
          url: '/system/departments',
        },
      ],
    })
  }

  return menus
}

export const getSystemModeTopPage = (roles: AuthRole[]): string | null => {
  const menus = getSystemModeMenus(roles)
  return _.get(menus, '[0].items[0].url', null)
}

export const checkSystemModePath = (pathname?: string): boolean => {
  // pathname未指定の場合現在pathnameを使う
  pathname = pathname ?? (_.get(window, 'location.pathname', '') as string)
  return pathname.startsWith('/system/')
}

export const getTopPage = (pa: UserPermissionAttributes | null) => {
  if (checkSystemModePath()) {
    const topPage = getSystemModeTopPage(pa?.roles ?? [])
    // nullの場合管理モードの有効メニューがない（権限を持ってない）
    if (topPage !== null) {
      return topPage
    }
  }

  return '/mypage'
}
